import { ApplicationRef, Component, OnDestroy, OnInit } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { MsalService } from '@azure/msal-angular';
import { Subject, concat, interval } from 'rxjs';
import { filter, first, takeUntil, tap } from 'rxjs/operators';
import packg from '../../package.json';
import { VrcBrowserStorageService } from './services/vrc-browser-storage.service';

import { VrcStorageService } from './services/vrc-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  public title = 'Guest 360 view';
  public appVersion = packg.version;
  constructor(
    private _bst: VrcBrowserStorageService,
    private _swUpdate: SwUpdate,
    private _appRef: ApplicationRef,
    private _authService: MsalService,
    private _st: VrcStorageService
  ) { }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
  ngOnInit(): void {

    // Must initialize MSAL
    this._authService.initialize().pipe(takeUntil(this._destroying$)).subscribe();
    if (this._swUpdate.isEnabled) {
      const appIsStable$ = this._appRef.isStable.pipe(first(isStable => isStable === true));
      const everyMinute$ = interval(60 * 1000);
      const everyMinuteOnceAppIsStable$ = concat(appIsStable$, everyMinute$);
      everyMinuteOnceAppIsStable$.pipe(
        tap(() => {
          this._swUpdate.checkForUpdate();
        }),
        takeUntil(this._destroying$),
      ).subscribe();

      this._swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        takeUntil(this._destroying$),
        tap(() => {
          if (confirm('New application version is available. Load New Version?')) {
            this._swUpdate.activateUpdate().then(() => document.location.reload());
            // window.location.reload();
          }
        })).subscribe();
    }
    // this._authService.instance.enableAccountStorageEvents();
    this._authService.handleRedirectObservable().pipe(takeUntil(this._destroying$)).subscribe();
    this._authService.instance.getConfiguration().auth.redirectUri = location.origin;
    this._st.isCreateLoyalty = false;
  }
}
